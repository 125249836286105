#header  {
    background: #656565;
    ul {
	margin-top: 20px;
    }
    #admin-logo {
	height: 40px
    }
}

.dashboard-module-content a {
    color: #8A5506;
}
.login input[type="submit"] {
    background: #F0953E;
    font-weight: bold;
}
